export default class GenericComponent {
    constructor() {
        GenericComponent.strateCTS()
        GenericComponent.socialCrowdRiffScript()

        window.addEventListener('resize', () => {
            GenericComponent.strateCTS()
        })
    }

    static strateCTS() {
        if ($('.strate-CTS')) {
            if (window.matchMedia('(max-width: 1000px)').matches) {
                const sameHeight = $('.strate-CTS-right').height()
                $('.strate-CTS-left').height(sameHeight)
            }
        }
    }

    static socialCrowdRiffScript() {
        const containerCrowdriff = $('.footer__crowdriff')
        if (containerCrowdriff) {
            if (window.matchMedia('(max-width: 1000px)').matches) {
                containerCrowdriff.remove()
            }
        }
    }
}
