export default class LangComponent {
    constructor() {
        LangComponent.langSwitcherNavbar()
        LangComponent.langSwitcherModal()
        LangComponent.closeLangModal()
    }

    static langSwitcherNavbar() {
        $('.tools__item__languages').on('click', () => {
            $('#dialog-lang-switcher').show()
        })
    }
    static closeLangModal() {
        $('#dialog-lang-switcher a, #dialog-lang-switcher .close').on('click', () => {
            LangComponent.setCookie('iris_language_choose', true, 30)
            $('#dialog-lang-switcher').hide()
        })
    }

    static langSwitcherModal(element) {
        if ($('#dialog-lang-switcher').length) {
            const userLang = navigator.language || navigator.userLanguage
            const browserLang = $('html').attr('lang')
            const cookie = LangComponent.getCookie('iris_language_choose')

            //console.log("Browser language: " + userLang + " | Website language: " + browserLang + " | cookie: " +cookie);

            if (userLang !== browserLang && cookie !== 'true') {
                // $('#dialog-lang-switcher').show();
            }
        }
    }

    static setCookie(cname, cvalue, exdays) {
        const d = new Date()
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
        const expires = `expires=${d.toUTCString()}`
        document.cookie = `${cname}=${cvalue};${expires};path=/`
    }

    static getCookie(cname) {
        const name = `${cname}=`
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    }
}
