/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import GenericComponent from './components/generic.component'
import LangComponent from './components/lang.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    // Generic component
    new NavbarComponent()
    new LangComponent()
    new GenericComponent()

    // Front page only
    if ($('.home').length > 0) {
        import(
            './services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    // Pages only
    if ($('.page').length > 0) {
        import(
            './components/page.component' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: PageComponent }) => {
            new PageComponent()
        })
    }

    // Trip only
    if ($('.single-customer_trip').length > 0) {
        import(
            './components/trip.component' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: TripComponent }) => {
            new TripComponent()
        })
    }

    // Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            './services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }
})
