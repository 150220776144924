export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.submenu()
        NavbarComponent.navbarModal()
        NavbarComponent.ecobarOpen()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            // Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic
        const currentLang = $('html').attr('lang')
        const codeLang = currentLang.slice(0, 2)

        if (currentLang.slice(0, 2) === codeLang) {
            logoBanner =
                IRISCollectionCustomer[
                    `imageLogoBanner${codeLang[0].toUpperCase() + codeLang.slice(1)}`
                    ]
            logoClassic =
                IRISCollectionCustomer[`imageLogo${codeLang[0].toUpperCase() + codeLang.slice(1)}`]
        }

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        // Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            if (body.hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('banner')

                // close ecobar
                $('body').removeClass('eco-bar-open')
                $('.eco-bar').addClass('eco-bar--hidden')
            } else {
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            }
        })

        // Open/Close submenu
        $(more).on('click', function () {
            $('body').addClass('submenu-open')
            $(this).parents('.menu__item').addClass('is-active')
        })

        $(prev).on('click', function (e) {
            e.preventDefault()
            $('body').removeClass('submenu-open')
            $(this).parents('.menu__item').removeClass('is-active')
        })
    }

    static submenu() {
        if (window.matchMedia('(min-width:1000px)').matches) {
            $('.menu__wrapper .menu__item').first().addClass('is-active')
        }

        $('.menu__item__link').on('click', function (e) {
            // second click (already open)
            if ($(this).parent('.menu__wrapper .menu__item').hasClass('is-active')) {
                return true
            }

            // first click (open submenu)
            $('.menu__wrapper .menu__item').removeClass('is-active').removeClass('has-submenu-open')
            $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
            $(this).parent('.menu__wrapper .menu__item').addClass('is-active')

            return false
        })
    }

    static navbarModal() {
        $('[data-element="navbar-modal-button"]').on('click', () => {
            $.ajax({
                url: IRISCollectionTheme.ajaxUrl,
                data: {
                    action: 'get_navbar_modal',
                },
                method: 'POST',
            }).done((response) => {
                $('[data-element="navbar-modal-content"]').html(response)
                $('.custom-navbar-modal').addClass('open')
                $('.custom-navbar-modal .close').on('click', (e) => {
                    $('.custom-navbar-modal').removeClass('open')
                })
            })

            return false
        })
    }

    static ecobarOpen() {
        // detect when eco bar is open
        if ($('[data-id="eco-bar"].eco-bar--hidden').length === 1) {
            $('body').removeClass('eco-bar-open')
            $('.home .header').css('min-height', '100svh')
        } else {
            $('body').addClass('eco-bar-open')
            $('.home .header').css('min-height', $(window).height() - $('.eco-bar').height())
        }
        $('[data-toggle="#eco-bar"]').on('click', () => {
            if ($('[data-id="eco-bar"].eco-bar--hidden').length === 1) {
                $('body').removeClass('eco-bar-open')
                $('.home .header').css('min-height', '100svh')
            } else {
                $('body').addClass('eco-bar-open')
                $('.home .header').css('min-height', $(window).height() - $('.eco-bar').height())
            }
        })
    }
}
